import React, { useState, useEffect, useRef, forwardRef } from 'react';
import gsap from 'gsap';
import { ASSETS } from '../assetManifest';

const BASE_Z_INDEX = -2000;

const Image = ({ src, size, width, height, style = {}, ...props }) => {
  const scale = size / Math.max(width, height);
  return (
    <img
      src={src}
      style={{
        width: width * scale,
        height: height * scale,
        ...style
      }}
      {...props}
    />
  );
};

const Sprite = forwardRef(({
  asset,
  size = 40,
  style = {},
  flipped = false,
  rotation = 0,
  ...props
}, ref) => {
  return (
    <div
      ref={ref}
      style={{
        fontSize: `${size}px`,
        lineHeight: 1,
        height: `${size}px`,
        width: `${size}px`,
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...style
      }}
      {...props}
    >
      <div
        style={{
          transform: `${flipped ? 'scaleX(-1)' : ''} rotate(${rotation}deg)`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image
          src={asset.path}
          width={asset.width}
          height={asset.height}
          size={size}
        />
      </div>
    </div>
  );
});

Sprite.displayName = 'Sprite';

const Shadow = ({ width = 20, height = 6 }) => (
  <div
    style={{
      position: 'absolute',
      width: `${width}px`,
      height: `${height}px`,
      backgroundColor: 'rgba(0,0,0,0.3)',
      borderRadius: '50%',
      left: '50%',
      bottom: '-3px',
      transform: 'translateX(-50%)'
    }}
  />
);

const ProgressBar = ({ progress, width = 32 }) => (
  <div 
    className="absolute top-0 left-50% transform -translate-x-1/2 -translate-y-full"
    style={{width: `${width}px`}}
  >
    <div className="w-full h-2 bg-gray-800 rounded-full overflow-hidden">
      <div
        className="h-full bg-blue-500 transition-all duration-300"
        style={{width: `${progress * 100}%`}}
      />
    </div>
  </div>
);

function GameStage({ game }) {
  const [tick, setTick] = useState(0);
  const [particles, setParticles] = useState([]);
  const elementRefs = useRef({});
  const characterRefs = useRef({});
  const animationRefs = useRef({});

  useEffect(() => {
    const interval = setInterval(() => {
      game.update(1);
      game.animationEvents.forEach(handleAnimation);
      game.clearAnimationEvents();
      setTick(t => t + 1);

      setParticles(prevParticles =>
        prevParticles
          .map(particle => ({
            ...particle,
            x: particle.x + particle.vx / 60,
            y: particle.y + particle.vy / 60,
            life: particle.life - 1/60
          }))
          .filter(particle => particle.life > 0)
      );
    }, 16); // Increased update rate for smoother movement

    return () => clearInterval(interval);
  }, []);

  const handleAnimation = (event) => {
    const element = elementRefs.current[event.elementId];
    const character = characterRefs.current[event.elementId];

    if (animationRefs.current[event.id]) {
      animationRefs.current[event.id].forEach(tween => tween.kill());
    }
    animationRefs.current[event.id] = [];

    switch (event.type) {
      case 'spawn':
        if (!element) return;
        gsap.from(element, {
          scale: 0,
          opacity: 0,
          duration: 0.3,
          ease: "back.out(1.7)"
        });
        break;

      case 'walk':
        if (!character) return;
        if (event.walking) {
          const timeline = gsap.timeline({
            repeat: -1,
            defaults: { duration: 0.3, ease: "sine.inOut" }
          });
          timeline
            .to(character, {
              y: -5,
              scaleY: 1.1,
              scaleX: 0.9,
            })
            .to(character, {
              y: 0,
              scaleY: 1,
              scaleX: 1,
            });
          animationRefs.current[event.id] = [timeline];
        }
        break;

      case 'stateChange':
        if (!character) return;
        if (event.state === 'training') {
          const timeline = gsap.timeline({
            repeat: -1,
            defaults: { duration: 0.5, ease: "sine.inOut" }
          });
          timeline
            .to(character, {
              rotate: -5,
              scale: 1.1,
            })
            .to(character, {
              rotate: 5,
              scale: 0.9,
            });
          animationRefs.current[event.id] = [timeline];
        }
        break;

      case 'remove':
        if (!element) return;
        gsap.to(element, {
          scale: 0,
          opacity: 0,
          duration: 0.3,
          onComplete: () => {
            if (element) {
              element.style.display = 'none';
            }
          }
        });
        break;

      case 'reputationGain':
        const newParticles = Array.from({ length: 8 }, () => ({
          id: Math.random(),
          x: event.x,
          y: event.y,
          vx: (Math.random() - 0.5) * 200,
          vy: -Math.random() * 200,
          life: 1
        }));
        setParticles(prev => [...prev, ...newParticles]);
        break;
    }
  };

  return (
    <div 
      className="h-full w-full relative bg-gradient-to-b from-amber-100 to-amber-200"
      style={{ zIndex: BASE_Z_INDEX }}
    >
      <div style={{
        position: 'absolute',
        left: game.entrance.x,
        top: game.entrance.y,
        transform: 'translate(-50%, -50%)',
        zIndex: Math.floor(game.entrance.y) + BASE_Z_INDEX
      }}>
        <Sprite asset={ASSETS.buildings.door} size={40} />
      </div>

      <div style={{
        position: 'absolute',
        left: game.trainingArea.x,
        top: game.trainingArea.y,
        transform: 'translate(-50%, -50%)',
        zIndex: Math.floor(game.trainingArea.y) + BASE_Z_INDEX
      }}>
        <Sprite asset={ASSETS.buildings.dojo} size={60} />
      </div>

      <div style={{
        position: 'absolute',
        left: game.exit.x,
        top: game.exit.y,
        transform: 'translate(-50%, -50%)',
        zIndex: Math.floor(game.exit.y) + BASE_Z_INDEX
      }}>
        <Sprite asset={ASSETS.buildings.door} size={40} />
      </div>

      {game.students.map((student) => (
        <div
          key={student.id}
          ref={el => elementRefs.current[student.id] = el}
          style={{
            position: 'absolute',
            left: student.x,
            top: student.y,
            transform: 'translate(-50%, -50%)',
            zIndex: Math.floor(student.y) + BASE_Z_INDEX,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '40px',
            height: '40px'
          }}
        >
          {student.state === 'training' && (
            <ProgressBar progress={student.progress} />
          )}
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <Shadow />
            <Sprite
              ref={el => characterRefs.current[student.id] = el}
              asset={ASSETS.humans.student}
              size={40}
              flipped={student.facingLeft}
            />
          </div>
        </div>
      ))}

      {particles.map(particle => (
        <div
          key={particle.id}
          style={{
            position: 'absolute',
            left: particle.x,
            top: particle.y,
            transform: 'translate(-50%, -50%)',
            opacity: particle.life,
            zIndex: 2000 + BASE_Z_INDEX
          }}
        >
          <Sprite asset={ASSETS.effects.sparkle} size={12} />
        </div>
      ))}
    </div>
  );
}

export default GameStage;
