import React, { useState, useEffect } from 'react';
import { ASSETS } from '../assetManifest';

const Image = ({ src, size = 24, width, height, style = {}, ...props }) => {
  const scale = size / Math.max(width, height);
  return (
    <img
      src={src}
      style={{
        width: width * scale,
        height: height * scale,
        ...style
      }}
      {...props}
    />
  );
};

const Button = ({ onClick, children, disabled, variant = 'default' }) => {
  const baseStyle = "p-2 rounded font-game transition-all duration-200 transform hover:scale-105";
  const variants = {
    default: "bg-red-800 text-gray-200 border border-red-600 hover:bg-red-700",
    secondary: "bg-gray-800 text-gray-300 border border-gray-600 hover:bg-gray-700",
    success: "bg-green-800 text-gray-200 border border-green-600 hover:bg-green-700"
  };

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${baseStyle} ${variants[variant]} ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
    >
      {children}
    </button>
  );
};

const HUD = ({ game }) => (
  <div className="absolute top-0 left-0 right-0 p-4 bg-gradient-to-b from-black to-transparent">
    <div className="max-w-3xl mx-auto bg-black bg-opacity-80 p-3 rounded-lg border border-red-800 shadow-lg">
      <div className="flex justify-between items-center text-gray-300 font-game">
        <div className="flex items-center">
          <Image src={ASSETS.resources.timer.path} width={ASSETS.resources.timer.width} height={ASSETS.resources.timer.height} size={24} />
          <span className="ml-2">{formatTime(game.getTimeLeft())}</span>
        </div>
        <div className="flex items-center">
          <Image src={ASSETS.resources.reputation.path} width={ASSETS.resources.reputation.width} height={ASSETS.resources.reputation.height} size={24} />
          <span className="ml-2">{game.reputation}/{game.reputationRequired}</span>
        </div>
        <div className="flex items-center">
          <Image src={ASSETS.resources.students.path} width={ASSETS.resources.students.width} height={ASSETS.resources.students.height} size={24} />
          <span className="ml-2">{game.studentCount}</span>
        </div>
      </div>
    </div>
  </div>
);

const GameOverModal = ({ game, onFinishGame }) => (
  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-80">
    <div className="bg-gray-900 p-8 rounded-lg border-2 border-red-800 shadow-2xl transform scale-up">
      <h2 className="text-3xl font-cinzel text-red-500 mb-6">
        {game.isWon ? "道場マスター" : "修行失敗"}
      </h2>
      <h3 className="text-xl font-game text-gray-300 mb-6">
        {game.isWon ? "Dojo Master!" : "Training Failed!"}
      </h3>
      <Button onClick={onFinishGame} variant="success">
        Train Again
      </Button>
    </div>
  </div>
);

const UpgradeModal = ({ game, isOpen, onClose }) => {
  const [activeTab, setActiveTab] = useState('training');

  if (!isOpen) return null;

  const tabs = {
    training: [
      { label: 'Speed Training', action: () => game.buySpeed(), cost: game.speedCost },
      { label: 'Skill Level', action: () => game.buySkill(), cost: game.skillCost }
    ],
    students: [
      { label: 'Recruit Student', action: () => game.buyStudent(), cost: game.studentCost }
    ],
    facilities: [
      { label: 'Upgrade Dojo', action: () => game.buyFacilities(), cost: game.facilitiesCost },
      { label: 'Reputation Boost', action: () => game.buyReputationGain(), cost: game.reputationCost }
    ]
  };

  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-70">
      <div className="bg-gray-900 p-6 rounded-lg border-2 border-red-800 shadow-2xl max-w-2xl w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-cinzel text-red-500">Dojo Upgrades</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-300">✕</button>
        </div>
        
        <div className="flex mb-4 border-b border-gray-700">
          {Object.keys(tabs).map(tab => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`px-4 py-2 ${activeTab === tab ? 'text-red-500 border-b-2 border-red-500' : 'text-gray-400'}`}
            >
              {tab.charAt(0).toUpperCase() + tab.slice(1)}
            </button>
          ))}
        </div>

        <div className="grid gap-3">
          {tabs[activeTab].map((upgrade, index) => (
            <Button
              key={index}
              onClick={upgrade.action}
              disabled={game.reputation < upgrade.cost}
              variant="default"
            >
              {upgrade.label} ({upgrade.cost} rep)
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${mins}:${secs.toString().padStart(2, '0')}`;
};

function GameUI({ game, onFinishGame }) {
  const [, forceUpdate] = useState();
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => forceUpdate({}), 30);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <HUD game={game} />

      <div className="absolute bottom-4 left-0 right-0 flex justify-center">
        <Button 
          onClick={() => setUpgradeModalOpen(true)}
          variant="secondary"
        >
          Open Dojo Menu
        </Button>
      </div>

      <UpgradeModal 
        game={game} 
        isOpen={upgradeModalOpen} 
        onClose={() => setUpgradeModalOpen(false)} 
      />

      {game.isGameOver && (
        <GameOverModal game={game} onFinishGame={onFinishGame} />
      )}
    </>
  );
}

export default GameUI;
