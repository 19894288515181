export const ASSETS = {
  backgrounds: {
    background: {
      path: 'assets/backgrounds/background.png',
      width: 390,
      height: 844
    }
  },
  buildings: {
    door: {
      path: 'assets/buildings/door.png',
      width: 128,
      height: 256
    },
    dojo: {
      path: 'assets/buildings/dojo.png',
      width: 512,
      height: 512
    }
  },
  humans: {
    student: {
      path: 'assets/humans/student.png',
      width: 128,
      height: 256
    }
  },
  resources: {
    timer: {
      path: 'assets/icons/timer.png',
      width: 128,
      height: 128
    },
    reputation: {
      path: 'assets/icons/reputation.png',
      width: 128,
      height: 128
    },
    students: {
      path: 'assets/icons/students.png',
      width: 128,
      height: 128
    }
  },
  effects: {
    sparkle: {
      path: 'assets/effects/sparkle.png',
      width: 64,
      height: 64
    }
  }
};
