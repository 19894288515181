export class GameSimulation {
  constructor() {
    this.screenWidth = 390;
    this.screenHeight = 844;

    this.reputation = 0;
    this.timeLeft = 120;
    this.isGameOver = false;
    this.isWon = false;
    this.reputationRequired = 1000;

    this.studentCount = 2;
    this.trainingSpeed = 1;
    this.reputationGain = 5;
    this.skillLevel = 2;
    this.facilities = 1;

    this.studentCost = 30;
    this.speedCost = 45;
    this.reputationCost = 60;
    this.skillCost = 75;
    this.facilitiesCost = 90;

    this.entrance = { x: 50, y: this.screenHeight - 100 };
    this.trainingArea = { x: this.screenWidth / 2, y: this.screenHeight / 2 };
    this.exit = { x: this.screenWidth - 50, y: this.screenHeight - 100 };

    this.students = [];
    this.animationEvents = [];
    this.spawnStudent();
  }

  getTimeLeft() {
    return Math.max(0, this.timeLeft);
  }

  update(deltaTime) {
    if (this.isGameOver) return;

    this.timeLeft -= deltaTime / 100;
    if (this.timeLeft <= 0) {
      this.timeLeft = 0;
      this.isGameOver = true;
      return;
    }

    if (this.students.length < this.studentCount) {
      this.spawnStudent();
    }

    this.students.forEach(student => {
      switch (student.state) {
        case 'entering':
          const toTraining = this.moveTowards(student, this.trainingArea, deltaTime);
          if (toTraining) {
            student.state = 'training';
            student.progress = 0;
            this.animationEvents.push({
              type: 'stateChange',
              id: `event-${Date.now()}-${Math.random()}`,
              elementId: student.id,
              state: 'training'
            });
          }
          break;

        case 'training':
          student.progress += (deltaTime / 100) * this.trainingSpeed;
          if (student.progress >= 1) {
            student.state = 'exiting';
            this.animationEvents.push({
              type: 'stateChange',
              id: `event-${Date.now()}-${Math.random()}`,
              elementId: student.id,
              state: 'exiting'
            });
            this.gainReputation();
          }
          break;

        case 'exiting':
          const toExit = this.moveTowards(student, this.exit, deltaTime);
          if (toExit) {
            this.animationEvents.push({
              type: 'remove',
              id: `event-${Date.now()}-${Math.random()}`,
              elementId: student.id,
              x: student.x,
              y: student.y
            });
            this.students = this.students.filter(s => s !== student);
          }
          break;
      }
    });
  }

  moveTowards(entity, target, deltaTime) {
    const dx = target.x - entity.x;
    const dy = target.y - entity.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    if (distance < 5) return true;

    // Increased base speed and adjusted deltaTime division
    const baseSpeed = 400; // doubled from 200
    const speed = (baseSpeed * deltaTime) / 16; // changed from 100 to 16 for faster movement
    
    entity.x += (dx / distance) * speed;
    entity.y += (dy / distance) * speed;
    
    entity.facingLeft = dx < 0;

    if (entity.state !== 'walking') {
      entity.state = 'walking';
      this.animationEvents.push({
        type: 'walk',
        id: `event-${Date.now()}-${Math.random()}`,
        elementId: entity.id,
        walking: true
      });
    }
    
    return false;
  }

  spawnStudent() {
    const student = {
      id: `student-${Date.now()}`,
      x: this.entrance.x,
      y: this.entrance.y,
      state: 'entering',
      progress: 0,
      facingLeft: false
    };
    this.students.push(student);
    this.animationEvents.push({
      type: 'spawn',
      id: `event-${Date.now()}-${Math.random()}`,
      elementId: student.id
    });
  }

  gainReputation() {
    const baseGain = this.reputationGain * this.skillLevel;
    const facilityBonus = this.facilities * 0.5;
    const totalGain = Math.ceil(baseGain * (1 + facilityBonus));
    
    this.reputation += totalGain;
    
    this.animationEvents.push({
      type: 'reputationGain',
      id: `event-${Date.now()}-${Math.random()}`,
      amount: totalGain,
      x: this.trainingArea.x,
      y: this.trainingArea.y
    });
    
    if (this.reputation >= this.reputationRequired) {
      this.reputation = this.reputationRequired;
      this.isWon = true;
      this.isGameOver = true;
    }
  }

  buyStudent() {
    if (this.reputation >= this.studentCost) {
      this.reputation -= this.studentCost;
      this.studentCount++;
      this.studentCost *= 2;
      this.animationEvents.push({
        type: 'upgrade',
        id: `event-${Date.now()}-${Math.random()}`,
        upgrade: 'student'
      });
    }
  }

  buySpeed() {
    if (this.reputation >= this.speedCost) {
      this.reputation -= this.speedCost;
      this.trainingSpeed++;
      this.speedCost *= 2;
      this.animationEvents.push({
        type: 'upgrade',
        id: `event-${Date.now()}-${Math.random()}`,
        upgrade: 'speed'
      });
    }
  }

  buyReputationGain() {
    if (this.reputation >= this.reputationCost) {
      this.reputation -= this.reputationCost;
      this.reputationGain++;
      this.reputationCost *= 2;
      this.animationEvents.push({
        type: 'upgrade',
        id: `event-${Date.now()}-${Math.random()}`,
        upgrade: 'reputation'
      });
    }
  }

  buySkill() {
    if (this.reputation >= this.skillCost) {
      this.reputation -= this.skillCost;
      this.skillLevel++;
      this.skillCost *= 2;
      this.animationEvents.push({
        type: 'upgrade',
        id: `event-${Date.now()}-${Math.random()}`,
        upgrade: 'skill'
      });
    }
  }

  buyFacilities() {
    if (this.reputation >= this.facilitiesCost) {
      this.reputation -= this.facilitiesCost;
      this.facilities++;
      this.facilitiesCost *= 2;
      this.animationEvents.push({
        type: 'upgrade',
        id: `event-${Date.now()}-${Math.random()}`,
        upgrade: 'facilities'
      });
    }
  }

  clearAnimationEvents() {
    this.animationEvents = [];
  }
}
