import React, { useState, useEffect, useRef } from 'react';
import Game from './game/Game';

function App() {
  const [refresh, setRefresh] = useState(0);
  const app = {};
  return (
    <div className="h-full w-full mx-auto relative">
      <Game 
        app={app} 
        onFinishBattle={() => setRefresh(prev => prev + 1)}
        key={refresh}
      />
    </div>
  );
}

export default App;
