import React, { useState, useEffect, useRef } from 'react';
import { Pointer } from 'lucide-react';

function isElementVisible(element) {
  if (!element) return false;
  const rect = element.getBoundingClientRect();
  return rect.width > 0 && rect.height > 0;
}

function findTargetElement(className, id) {
  if (className) {
    const elements = document.getElementsByClassName(className);
    return elements.length > 0 ? elements[0] : null;
  }
  if (id) {
    return document.getElementById(id);
  }
  return null;
}

function getDialogPosition(pointerPosition) {
  if (!pointerPosition) return 'bottom-4 right-4';
  
  const centerX = window.innerWidth / 2;
  const centerY = window.innerHeight / 2;
  
  // Determine which quadrant the pointer is in
  const isRight = pointerPosition.x > centerX;
  const isBottom = pointerPosition.y > centerY;
  
  // Place dialog in opposite corner
  if (isRight && isBottom) return 'top-4 left-4';
  if (isRight && !isBottom) return 'bottom-4 left-4';
  if (!isRight && isBottom) return 'top-4 right-4';
  return 'bottom-4 right-4';
}

function TutorialPointer({ className, id }) {
  const [position, setPosition] = useState(null);
  const pointerRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const targetElement = findTargetElement(className, id);
      if (targetElement && isElementVisible(targetElement)) {
        const rect = targetElement.getBoundingClientRect();
        setPosition({
          x: rect.left + rect.width / 2,
          y: rect.top + rect.height / 2
        });
      } else {
        setPosition(null);
      }
    }, 100); // Update every 100ms

    return () => clearInterval(interval);
  }, [className, id]);

  if (!position) return null;

  return (
    <>
      <div 
        className="fixed inset-0 pointer-events-none"
        style={{
          background: `radial-gradient(circle 200px at ${position.x}px ${position.y}px, 
                      transparent 0%, transparent 20%, rgba(0, 0, 0, 0.5) 21%)`,
          zIndex: 20
        }}
      />
      <div 
        ref={pointerRef}
        className="fixed animate-bounce pointer-events-none"
        style={{ 
          left: position.x,
          top: position.y,
          transform: 'translate(-50%, -50%) rotate(-45deg)',
          zIndex: 20
        }}
      >
        <Pointer size={32} className="text-white" />
      </div>
    </>
  );
}

function TutorialOverlay({ game }) {
  const [tick, setTick] = useState(0);
  const [pointerPosition, setPointerPosition] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!game.tutorial?.active) {
        return
      }

      const targetElement = findTargetElement(game.tutorial.className, game.tutorial.targetElementId);
      if (targetElement && isElementVisible(targetElement)) {
        const rect = targetElement.getBoundingClientRect();
        setPointerPosition({
          x: rect.left + rect.width / 2,
          y: rect.top + rect.height / 2
        });
      } else {
        setPointerPosition(null);
      }
      setTick(t => t + 1);
    }, 30);
    return () => clearInterval(interval);
  }, []);

  if (!game.tutorial?.active) return null;

  const dialogPosition = getDialogPosition(pointerPosition);

  return (
    <>
      <TutorialPointer 
        className={game.tutorial.className} 
        id={game.tutorial.targetElementId}
      />
      <div 
        className={`fixed ${dialogPosition} mx-4 p-6 bg-white text-black font-game text-2xl
                    rounded-lg border-4 border-black shadow-xl max-w-md`}
        style={{ pointerEvents: 'none', zIndex: 20 }}
      >
        {game.tutorial.text}
      </div>
    </>
  );
}

export default TutorialOverlay;
