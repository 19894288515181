import React, { useRef } from 'react';
import { GameSimulation } from './gameSimulation';
import GameStage from './GameStage';
import GameUI from './GameUI';
import TutorialOverlay from './TutorialOverlay';

function Game({ app, levelId = null, onFinishGame }) {
  const renderer = useRef(null);

  if (!renderer.current) {
    renderer.current = new GameSimulation();
  }

  return (
    <div className="relative w-full h-full">
      <div className="absolute inset-0">
        <GameStage game={renderer.current} />
      </div>
      {/* Game UI usually needs to be above the stage but below tutorial */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="pointer-events-auto">
          <GameUI game={renderer.current} onFinishGame={onFinishGame} />
        </div>
      </div>

      {/* Tutorial overlay should be topmost */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="pointer-events-auto">
          <TutorialOverlay game={renderer.current} />
        </div>
      </div>
    </div>
  );
}

export default Game;