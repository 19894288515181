import ReactDOM from 'react-dom/client';
import WebFontProvider from './WebFontProvider';
import App from './App';
import React, { useState, useEffect } from 'react';

const ResponsiveLayout = ({ children }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isDesktop) {
    return (
      <div className="w-screen h-screen">
        {children}
      </div>
    );
  }

  return (
    <div className="min-h-screen w-full relative">
      {/* Desktop-only background with blur */}
      <div className="fixed inset-0 z-[-10000] bg-black">
        <img
          src="assets/thumbnail.png"
          alt="Background"
          className="w-full h-full object-cover opacity-50 blur-xl"
        />
      </div>

      {/* Content container */}
      <div className="relative min-h-screen mx-auto flex items-center justify-center">
        {/* Fixed size container on desktop */}
        <div className="w-[390px] h-[844px] rounded-3xl shadow-2xl overflow-hidden">
          {/* Content layer - allows negative z-indices to show */}
          <div className="relative w-full h-[844px] overflow-auto">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <WebFontProvider>
    <ResponsiveLayout>
      <App />
    </ResponsiveLayout>
  </WebFontProvider>
);

export default ResponsiveLayout;